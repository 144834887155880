import { render, staticRenderFns } from "./TurnoCard.vue?vue&type=template&id=5d58afd6&scoped=true"
import script from "./TurnoCard.vue?vue&type=script&lang=js"
export * from "./TurnoCard.vue?vue&type=script&lang=js"
import style0 from "./TurnoCard.vue?vue&type=style&index=0&id=5d58afd6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d58afd6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VDivider,VIcon,VRow,VSpacer})
