<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Turnos' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Turno</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="turno">
      <TurnoCard :turno="turno"> </TurnoCard>
    </div>
  </v-container>
</template>

<script>
import TurnoCard from "../components/TurnoCard.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "TurnoDetalle",
  components: {
    TurnoCard,
  },
  data() {
    return {
      turno: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getTurno(this.id)
      .then((response) => {
        this.turno = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
