<template>
  <div v-if="this.turno">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.turno.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card>
      <v-container v-if="error">
        <v-row>
          <v-col>
            <v-alert dense type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.turno.get_tipo_display }}: {{ this.turno.nombre }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.turno.descripcion }}
          </v-card-subtitle>
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <br />
            <p><strong>Nombre: </strong>{{ this.turno.nombre }}</p>
            <p>
              <strong>Color: </strong
              ><span :style="'color: ' + this.turno.color">
                {{ this.turno.color }}
              </span>
            </p>

            <p><strong>Tipo: </strong>{{ this.turno.get_tipo_display }}</p>
            <p>
              <strong>Horario: </strong>{{ this.turno.horario_simple.__str__ }}
            </p>
            <p>
              <strong>Incluye Feriados: </strong
              >{{ this.turno.get_incluye_feriados_display }}
            </p>
            <p v-if="this.turno.incluye_feriados != 1">
              <strong>Horario Feriados: </strong
              >{{ this.turno.horario_feriado.__str__ }}
            </p>
            <p>
              <strong>Estado: </strong
              >{{ this.turno.estado ? "Activado" : "Desactivado" }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" disabled
          ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
        >
        <v-btn color="error" @click="handleDialog"
          ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "TurnoCard",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      loading: true,
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar el turno?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: null,
      error: "",
    };
  },
  created() {},
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.turno.id;
    },
    handleDialogYes(id) {
      //console.log(id);
      PresentismoService.deleteTurno(id)
        .then((response) => {
          this.$router.push({
            name: "Turnos",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error = "Error: El registro está protegido.";
        });
    },
  },
  props: {
    turno: {
      type: Object,
      required: true,
    },
  },
  computed: {
    turnoColor() {
      return {
        // color: this.turno ? this.turno.color + ";" : "yellow;",
        color: this.turno.color + ";",
      };
    },
  },
};
</script>

<style scoped>
.turnoColor {
  color: v-bind("this.turno.color");
}

.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
